import { put, takeLatest } from "@redux-saga/core/effects";
import { appLoading, appReady } from "./actions";
import { APP_START } from "./constants";

function* onStart() {
    yield put(appLoading());

    yield put (appReady());
}

function* saga() {
    yield takeLatest(APP_START, onStart);
};

export default saga;