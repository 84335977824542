import { Grid } from "@mui/material";

const RatuszKazimierski = () => {
    return (
        <div className="sub-page-container">
            <Grid container md={8} className="article-content-container">
                <div className="article-date">
                    22 października 2023
                </div>
                <div className="article-title">
                    Ratusz kazimierski - pomnik wielkości dawnego miasta
                </div>
                <div className="article-content">
                    Ratusz kazimierski jest jednym z kilku zachowanych ratuszów na terenie Krakowa. Co ciekawe nie zachował się do dzisiejszych czasów ten najważniejszy, a więc miasta Kraków – na Rynku Głównym pozostała jedynie Wieża Ratuszowa wraz podziemiem. Ale zarówno wieżą, jak i pozostałymi ratuszami zajmiemy się w osobnym artykule, a dziś skupimy się na budowli, która przez kilka wieków była miejscem, z którego zarządzano miastem Kazimierz.
                </div>
                <img src="images/RatuszKazimierski.jpg" className="article-image" />
                <div className="article-content">
                    Owe miasto powstało w 1335 roku, poprzez nadanie mu przywilejów miejskich przez króla Kazimierza Wielkiego. Wytyczono wówczas ogromny plac, niewiele mniejszy niż obecny Rynek Główny, wybudowano także pierwszy, drewniany ratusz. Murowana, gotycka budowla, której mury do dziś znajdują się w piwnicach obiektu, powstała w 1414 roku. Dwa największe remonty Ratusza związane są z pożarami, które nawiedzały to miejsce w 1578 i 1623 roku. Po drugim z tych pożarów nadano budowli renesansowe cechy, pojawiły się attyki typu krenelażowego, a także zainstalować zegar wieżowy, który jest obecnie najstarszym czynnym tego typu obiektem w mieście. Obecna bryła budynku w dużej mierze przypomina właśnie tamtą postać, choć później obiekt również był remontowany – m.in. w XIX wieku. 
                </div>
                <div className="article-content">
                    W roku 1800 Kazimierz zgodnie z austriackim zarządzeniem został przyłączony do Krakowa, więc budynek przestał pełnić funkcję ratusza. Z tym przyłączeniem wiąże się ciekawostka odnośnie nazwy ulicy Krakowskiej. Rzadko bowiem zdarza się, by w danym mieście występowała nazwa ulicy pochodząca od tegoż właśnie miasta. Przyłączając Kazimierz do Krakowa postanowiono zmienić jedynie nazwy tych ulic, które już występowały w mieście Kraków. Jak łatwo się domyślić, ulicy Krakowskiej w Krakowie nie było. Natomiast w Kazimierzu była to główna arteria miasta, wytyczona już w ramach planu lokacyjnego miasta. Od wieków przebiegał tedy szlak handlowy, zwany drogą solną, gdyż prowadził w kierunku Wieliczki. Sama ulica w obecnym kształcie, jaki znamy istnieje od 1881 roku, w momencie przyłączenia tylko środkowy jej odcinek nosił tą nazwę. Wówczas północny odcinek nazywano ulicą Glinianą lub Szewską, natomiast południowy Solną.
                </div>
                <div className="article-content">
                    Poniżej prezentujemy dwa zachowane do dziś wyobrażenia XIX-wiecznego ratusza:
                </div>
                <img src="images/AleksanderGryglewskiRatuszKazimierskiSprzed1876.jpg" className="article-image" />
                <div className="article-content">
                    Obraz Aleksandra Gryglewskiego sprzed 1876 roku prezentujący ratusz kazimierski przed przebudową z lat 1875-1876. Widoczne są na nim dobudowane domki, które były pozostałością sukiennic. Dziś oczywiście już te domki nie istnieją.
                </div>
                <img src="images/IgnacyKriegerRatuszKazimierski1870.jpg" className="article-image" />
                <div className="article-content">
                    Fotografia Ignacego Kriegera przedstawiająca ratusz kazimierski w swym oryginalnym kształcie z 1870 roku.
                </div>
                <div className="article-content">
                    W roku 1806 budynek był w bardzo kiepskim stanie. Został przeznaczony do rozbiórki i wystawiony na licytację. Na szczęście budowlę udało się uchronić przed zniszczeniem, a w późniejszych latach pełnił funkcję edukacyjną. W XIX wieku znajdowała się tu Szkoła Przemysłowo-Handlowa, a następnie aż do II Wojny Światowej szkoła żydowska. Po wojnie budynek przejęło Muzeum Etnograficzne, które znajduje się tu do dziś.
                </div>
                <div className="article-content">
                    Na ścianie dawnego ratusza znajdziemy tablicę zatytułowaną „Przyjęcie Żydów do Polski w Średniowieczu”. Tablica została ufundowana przez Gminę Żydowską, a wyrzeźbił ją Henryk Hochman w 1907 roku. Podczas wojny tablica została usunięta, wróciła na swoje miejsce dopiero w 1996 roku.
                </div>
                <img src="images/TablicaNaRatuszuKazimierskim.jpg" className="article-image" />
                <div className="article-content">
                    Przed Ratuszem znajduje się wspomniany już wcześniej Plac Wolnica. Wróćmy jeszcze do tematu jego rozmiaru. Dotąd wspomnieliśmy jedynie, że był to plac większy niż obecny, ale by zobrazować jego rozmiar warto dodać, że północna i wschodnia pierzeja zostały zachowane, natomiast od strony zachodniej sięgał ulicy Augustiańskiej, a więc np. Kościół św. Katarzyny znajdował się na brzegu placu. Od południa natomiast plac kończył się w rejonie ulicy Skawińskiej oraz domu Esterki, znajdującego się przy Krakowskiej 46. Środkiem rynku biegł wspominany już szlak solny, a więc dzisiejsza ulica Krakowska. Obecny kształt, jak i nazwa Placu Wolnica zostały ustanowione za czasów Wolnego Miasta Kraków (1815-1846).
                </div>
                <div className="article-content">
                    W 1970 roku na Placu powstała fontanna, którą udekorowano rzeźbą zatytułowaną „Trzej grajkowie”. Twórcą tej instalacji artystycznej był jeden z najwybitniejszych krakowskich rzeźbiarzy, Bronisław Chromy. W wielu miejscach w mieście możemy znaleźć jego dzieła, o czym też z pewnością kiedyś powstanie artykuł. Tu warto podkreślić, że każdy, kto był w Krakowie, zapewne przynajmniej jedną jego rzeźbę widział. Kto bowiem, zwiedzając miasto, nie widział Smoka Wawelskiego?
                </div>
                <img src="images/FontannaRzezbaChromego.jpg" className="article-image" />
            </Grid>
        </div>
    );
};

export default RatuszKazimierski;