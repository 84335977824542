import { all, fork } from "@redux-saga/core/effects";
import app from "./saga";
import routing from "./components/Routing/saga";

function* saga() {
    yield all([
        fork(app),
        fork(routing)
    ])
}

export default saga;