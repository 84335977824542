import { Grid } from "@mui/material";
import Facebook from "../../../icons/Facebook";
import Instagram from "../../../icons/Instagram";

const Footer = () => {
    return (
        <div className="footer">
            <Grid md={8} className="footer-container" container>
                <Grid item xs={12} md={6}>
                    <div className="footer-title">
                        Poznaj Kraków z nami!
                    </div>
                    <div className="footer-content">
                        Kraków to nie tylko Kościół Mariacki, Sukiennice i Wawel. Na naszej stronie znajdziesz sporo artykułów o równie pięknych, lecz mniej znanych miejscach w mieście.
                    </div>
                </Grid>
                <Grid item xs={12} md={6} container className="footer-icons">
                        <div className="footer-icon">
                            <Facebook />
                        </div>
                        <div className="footer-icon">
                            <Instagram />
                        </div>
                </Grid>
            </Grid>
            <Grid md={8} className="footer-rights" container>
                <Grid item>
                    Copyright &copy; Poznaj Kraków 2023. Wszelkie prawa zastrzeżone. Realizacja 
                    <a href="https://www.devline.pl" className="footer-link"> Devline</a>.
                </Grid>
            </Grid>
        </div>
    );
}

export default Footer;