import { FunctionComponent } from "react";

export enum RouteType {
    Home = "Home",
    Kazimierz = "Kazimierz",
    StareMiasto = "StareMiasto",
    Podgorze = "Podgorze",
    Pozostale = "Pozostale",

    SpacerPoNajpopularniejszychKrakowskichTeatrach = "SpacerPoNajpopularniejszychKrakowskichTeatrach",
    SladyPrzedlokacyjnegoKrakowa = "SladyPrzedlokacyjnegoKrakowa",
    KamiennyKrag = "KamiennyKrag",
    RatuszKazimierski = "RatuszKazimierski"
}

export interface Route {
    type: RouteType,
    path: string,
    Page: FunctionComponent<{}>,
    children?: Route[]
}