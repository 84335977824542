import { mainPodgorzeArticle } from "../../data/podgorze-articles";
import OneBigArticle from "../../modules/OneBigArticle/OneBigArticle";

const Podgorze = () => {
  
    const article = mainPodgorzeArticle;
    
    return (
        <div className="sub-page-container">
            <OneBigArticle article={article} />
        </div>
    );
  }
  
  export default Podgorze;
  