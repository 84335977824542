import { Grid } from "@mui/material";

const SladyPrzedlokacyjnegoKrakowa = () => {

    return (
        <div className="sub-page-container">
            <Grid container md={8} className="article-content-container">
                <div className="article-date">
                    1 października 2023
                </div>
                <div className="article-title">
                    Ślady przedlokacyjnego Krakowa
                </div>
                <div className="article-content">
                    W nieiejszym artykule przyjrzymy się trzem miejscom Starego Miasta, w których spacerując po dzisiejszym mieście możemy odnaleźć ten Kraków przedlokacyjny, mający swój początek przed 1257 rokiem. We wszystkich trzech przypadkach będziemy mieć do czynienia z budowlami sakralnymi.
                </div>
                <div className="article-content">
                    Zacznijmy od krótkiego przypomnienia historii lokacji miasta. 5 czerwca 1257 roku zostało nadane miastu prawo lokacyjne przez księcia Bolesława Wstydliwego, a wraz z nim przede wszystkim kształt urbanistyczny, który widzimy do dzisiaj, oparty na szachownicy. W centrum wytyczono olbrzymi kwadratowy plac, a z każdego jego boku wychodziły trzy prostopadłe do pierzei ulice. W kilku przypadkach od tych reguł występowały ustępstwa, jak skierowanie ulicy Grodzkiej w kierunku zamku oraz zakręcające Bracka i Gołębia. W tym drugim przypadku nie mamy potwierdzonych informacji, dlaczego zdecydowano się na taki krok - być może wówczas znajdowały się tam budowle, które chciano zachować.
                </div>
                <img src="images/KosciolMariacki.jpg" className="article-image" />
                <div className="article-content">
                    Nasz krótki spacer, bo biegnący jedynie z płyty Rynku Głównego na ulicę Grodzką, rozpoczniemy od budowli, którą zna każdy, kto chociaż raz był w Krakowie (a pewnie i sporo ludzi, którzy miasta dotąd nie odwiedzili). Mowa oczywiście o Bazylice Mariackiej, jednej z najpiękniejszych gotyckich budowli w naszym kraju. Początki obecnego Kościoła Mariackiego sięgają roku około 1290, kiedy to rozpoczęto budowę świątyni, a więc czasów polokacyjnych. Dlaczego zatem zaczynamy nasz spacer właśnie tutaj?
                </div>
                <div className="article-content">
                    Między innymi dlatego, że przedlokacyjne pozostało położenie samej budowli. Wcześniej w tym miejscu stała drewniana świątynia, która w latach 1221-1222 została zastąpiona murowaną, romańską świątynią ufundowaną przez ówczesnego biskupa Iwona Odrowąża. Krakowianie nie nacieszyli się jednak zbyt długo ową budowlą, gdyż została ona zburzona podczas najazdu przez tatarów w roku 1241. Podczas lokacji Krakowa, w miejscu obecnego Kościoła Mariackiego znajdowały się więc ruiny poprzedniej romańskiej świątyni. 
                </div>
                <div className="article-content">
                    Jak już wspominałem, w 1290 roku podjęto decyzję o powstaniu kościoła w stylu gotyckim, jednak do jego budowy wykorzystano częściowo pozostałe po poprzedniku fundamenty, stąd charakterystyczne ułożenie Kościoła Mariackiego względem innych budynków na płycie Rynku. Drugą taką niesymetrycznie umieszczoną budowlą na krakowskim Rynku jest drugi punkt naszego spaceru.
                </div>
                <img src="images/KosciolSwWojciecha.jpg" className="article-image" />
                <div className="article-content">
                    W niewielkiej odległości od Kościoła Mariackiego znajduje się niewielki biały kościółek, który widzicie na powyższym zdjęciu. Jest to oczywiście Kościół św. Wojciecha, romański budynek, którego początki sięgają 2 połowy XI wieku. Mimo tak odległej w historii daty, odkryto dowody, że wcześniej stał tu kościół drewniany - jego ślady sięgają przełomu X i XI wieku, a legenda głosi, że powstał w miejscu, gdzie za życia kazania prowadził sam święty Wojciech, który przebywał w Krakowie podczas podróży z Węgier do Prus (od tego wydarzenia zresztą ma pochodzić wezwanie nadane świątyni).
                </div>
                <div className="article-content">
                    Pierwsze, co zwraca uwagę turystów szukających wejścia do kościoła jest fakt, iż znajduje się ono poniżej poziomu rynku. Początkowo niewielka świątynia znajdowała się nawet na wzniesieniu, jednak na przestrzeni lat poziom rynku podniósł się na tyle, że wejście znalazło się pod poziomem bruku (powodem tego była ilość zanieczyszczeń w centrum miasta, ale to temat na osobny artykuł). 
                </div>
                <div className="article-content">
                    Dziś we wnętrzu Kościoła św. Wojciecha, oprócz części, gdzie odprawiane są msze (w tym najpóźniejsza msza w mieście, rozpoczynająca się o godzinie 21:37), znajduje się również wystawa przygotowana przez Muzeum Archeologiczne. Podczas zwiedzania możemy poznać historię samego kościoła, ale także Rynku Głównego oraz św. Wojciecha. Jedną z ciekawszych elementów ekspozycji są pozostałości mającej ponad 1000 lat podłogi, a także kamiennych posadzek oraz murów romańskich.
                </div>
                <img src="images/KosciolSwAndrzeja.jpg" className="article-image" />
                <div className="article-content">
                    Ostatnim punktem naszej wyprawy jest kościół znajdujący się na ulicy Grodzkiej, noszący imię Św. Andrzeja. Budowla ma swoje początki aż w 1079 roku, kiedy to została wybudowana... poza Krakowem. Dziś ciężko nam sobie wyobrazić, że w okolicach ulicy Grodzkiej nie było miasta, ale w ówczesnych czasach istniała tutaj osada o nazwie Okół, której to właśnie wspominany kościół był główną farą. 
                </div>
                <div className="article-content">
                    Budynek ten przypomina nam, że w dawnych czasach kościół oprócz znanej nam dziś funkcji związanych z wiarą, pełnił także inną - obronną. Masywne romańskie mury były tak trudne do zdobycia, że podczas najazdu tatarów w 1241 roku (tego, w którym zniszczono budowlę znajdujący się w miejscu dzisiejszego Kościoła Mariackiego), obiektu z Okołu nie udało się zdobyć. Tak było również podczas pozostałych najazdów, jedynie w 1260 tatarom udało się prawdopodobnie częściowo naruszyć bryłę kościoła. 
                </div>
                <div className="article-content">
                    Jako ciekawostkę, warto dodać, że przez pewien czas Kościół św. Andrzeja otoczony był... wałem oraz fosą. Stało się tak w 1243 roku, za sprawą Konrada Mazowieckiego, który to postanowił zwiększyć obronność tego miejsca. Natomiast sam Okół, w XIV wieku już na stałe został włączony do Krakowa, choć istnieją zapiski historyczne wskazujące, że przez pewien czas sam posiadał prawa miejskie.
                </div>
                <div className="article-content">
                    Tym samym odwiedziliśmy właśnie trzy najbardziej widoczne ślady przedlokacyjnego Krakowa. Na poniższej mapie zostały zaznaczone wszystkie punkty trasy.
                </div>
                <div className="article-map">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1b5Bm7hUwi7QDpxGWGgq9MJKJtJ408wlH&ehbc=2E312F" width="100%" height="100%"></iframe>
                </div>
            </Grid>
        </div>
    );
}

export default SladyPrzedlokacyjnegoKrakowa;