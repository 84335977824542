import { useAppSelector } from "../../../hooks/redux";
import { Navigate, Route, Routes as RoutesRRD } from "react-router-dom";
import { createElement } from "react";

const Routes = () => {
    const routes = useAppSelector(state => state.routing.routes);
    const defaultRoute = useAppSelector(state => state.routing.defaultRoute);

    return (
        <RoutesRRD>
            {routes.map(route =>
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <>
                            {createElement(route.Page)}
                        </>
                    }
                />)}
        </RoutesRRD>
    );
}

export default Routes;