import PageHeader from "../../app/components/PageHeader/PageHeader";
import { mainPageMainArticle, threeMainPageArticles } from "../../data/main-page-articles";
import OneBigArticle from "../../modules/OneBigArticle/OneBigArticle"
import ThreeColumnsArticles from "../../modules/ThreeColumnsArticles/ThreeColumnsArticles";


const Home = () => {
  
    const mainArticle = mainPageMainArticle;
    const threeArticles = threeMainPageArticles;

    return (
        <>
            <PageHeader />
            <OneBigArticle article={mainArticle} />
            <ThreeColumnsArticles articles={threeArticles} />
        </>
    );
  }
  
  export default Home;
  