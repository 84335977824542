
import { Grid, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';

const PageHeader = () => {
  
    return (
        <Grid xs={12} textAlign="center"  className="header-theme">
            <Grid xs={12} className="big-title">
            Poznaj Kraków
            </Grid>
            <Grid xs={12} className="little-title">
            Mniej oczywista historia Krakowa
            </Grid>
        </Grid>
    );
  }
  
  export default PageHeader;
  