import { mainStareMiastoArticle } from "../../data/stare-miasto-articles";
import OneBigArticle from "../../modules/OneBigArticle/OneBigArticle";

const StareMiasto = () => {
  
    const article = mainStareMiastoArticle;
    
    return (
        <div className="sub-page-container">
            <OneBigArticle article={article} />
        </div>
    );
  }
  
  export default StareMiasto;
  