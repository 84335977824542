import { MenuItem } from "../../../models/menuItem";

export const menuData : MenuItem[] = [
    {
        name: 'Stare Miasto',
        link: '/stare-miasto'
    },
    {
        name: 'Kazimierz',
        link: '/kazimierz'
    },
    {
        name: 'Podgórze',
        link: '/podgorze'
    },
    {
        name: 'Pozostałe dzielnice',
        link: '/pozostale'
    }
];