import { Article } from "../../models/article";

export const spacerPoNajpopularniejszychKrakowskichTeatrach : Article = {
    navlink: '/spacer-po-najpopularniejszych-krakowskich-teatrach',
    articleImage: 'images/TeatrSlowackiego.jpg',
    location: 'Kilka dzielnic',
    title: 'Spacer po najpopularniejszych krakowskich teatrach',
    shortDesc: 'Kraków od dziejów nazywany był miastem sztuki. Wiele wybitnych postaci teatru związanych jest bądź było z naszym miastem. Zapraszamy na spacer po najpopularniejszych Teatrach w mieście, a także krótką lekcję historii.',
    date: '8 października 2023',
    authorImage: 'images/KamilMarkiewicz.jpg',
    authorName: 'Kamil Markiewicz'
};