import { useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux';
import { startApp } from './actions';
import Routes from './components/Routing/Routes';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import About from './components/About/About';


const App = () => {

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(startApp())
  }, [dispatch]);


  return (
    <>
      <BrowserRouter>
        <Menu />
        <Routes />
      </BrowserRouter>
      {/* <About /> */}
      <Footer />

    </>

      
  );
}

export default App;
