import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { menuData } from "./menuData";
import { MenuProps } from "./types";

const MobileMenu = (props : MenuProps) => {

    //const [mobileOpen, setMobileOpen] = useState(false);
    const {handleDrawerToggle, setMobileOpen} = props;

    return (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
            <Typography variant="h6" sx={{ my: 2 }} className="menu-logo">
                Poznaj Kraków
            </Typography>
            <Divider />
            <List className="menu-elements">
                {menuData.map((menuItem) => (
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText>
                            <NavLink to={menuItem.link} className="mobile-nav-element">{menuItem.name}</NavLink>
                        </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default MobileMenu