import { mainPozostaleArticle } from "../../data/pozostale-articles";
import OneBigArticle from "../../modules/OneBigArticle/OneBigArticle";

const Pozostale = () => {
  
    const article = mainPozostaleArticle;
    
    return (
        <div className="sub-page-container">
            <OneBigArticle article={article} />
        </div>
    );
  }
  
  export default Pozostale;
  