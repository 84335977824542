import { Article } from "../../models/article";

export const kazimierskiRatusz : Article = {
    navlink: '/ratusz-kazimierski',
    articleImage: 'images/RatuszKazimierski.jpg',
    location: 'Kazimierz',
    title: 'Ratusz kazimierski - pomnik wielkości dawnego miasta',
    shortDesc: 'Dawniej ratusz zarządzający osobnym miastem, dziś budynek Muzeum Etnograficznego. Jak daleko wstecz możemy sięgnąć, sprawdzając historię tego miejsca?',
    date: '22 października 2023',
    authorImage: 'images/KamilMarkiewicz.jpg',
    authorName: 'Kamil Markiewicz'
};