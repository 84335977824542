import { Box, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { menuData } from "./menuData";

const MenuContent = () => {

    return (
        <>
            <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
                Poznaj Kraków
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="menu-elements">
            <ul>
            {menuData.map((menuItem) => (
                <li>
                            <NavLink to={menuItem.link}>
                                {menuItem.name}
                            </NavLink>
                        </li>
            ))}
                </ul>
            </Box>
          </>
    );
};

export default MenuContent;