import KamiennyKrag from "../../../features/articles/podgorze/KamiennyKrag";
import RatuszKazimierski from "../../../features/articles/kazimierz/RatuszKazimierski";
import SpacerPoNajpopularniejszychKrakowskichTeatrach from "../../../features/articles/pozostale/SpacerPoNajpopularniejszychKrakowskichTeatrach";
import SladyPrzedlokacyjnegoKrakowa from "../../../features/articles/stareMiasto/SladyPrzedlokacyjnegoKrakowa";
import Home from "../../../features/home/Home";
import Kazimierz from "../../../features/kazimierz/Kazimierz";
import Podgorze from "../../../features/podgorze/Podgorze";
import Pozostale from "../../../features/pozostale/Pozostale";
import StareMiasto from "../../../features/stareMiasto/StareMiasto";
import { Route, RouteType } from "./types";

export const routes: { [key in RouteType]: Route } = {
    [RouteType.Home]: {
        type: RouteType.Home,
        path: '/',
        Page: Home
    },
    [RouteType.Kazimierz]: {
        type: RouteType.Kazimierz,
        path: '/kazimierz',
        Page: Kazimierz
    },
    [RouteType.StareMiasto]: {
        type: RouteType.StareMiasto,
        path: '/stare-miasto',
        Page: StareMiasto
    },
    [RouteType.Podgorze]: {
        type: RouteType.Podgorze,
        path: '/podgorze',
        Page: Podgorze
    },
    [RouteType.Pozostale]: {
        type: RouteType.Pozostale,
        path: '/pozostale',
        Page: Pozostale
    },

    [RouteType.SpacerPoNajpopularniejszychKrakowskichTeatrach]: {
        type: RouteType.SpacerPoNajpopularniejszychKrakowskichTeatrach,
        path: '/spacer-po-najpopularniejszych-krakowskich-teatrach',
        Page: SpacerPoNajpopularniejszychKrakowskichTeatrach
    },
    [RouteType.SladyPrzedlokacyjnegoKrakowa]: {
        type: RouteType.SladyPrzedlokacyjnegoKrakowa,
        path: '/slady-przedlokacyjnego-krakowa',
        Page: SladyPrzedlokacyjnegoKrakowa
    },
    [RouteType.KamiennyKrag]: {
        type: RouteType.KamiennyKrag,
        path: '/kamienny-krag',
        Page: KamiennyKrag
    },
    [RouteType.RatuszKazimierski]: {
        type: RouteType.RatuszKazimierski,
        path: '/ratusz-kazimierski',
        Page: RatuszKazimierski
    }
};

export default routes;