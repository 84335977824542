import { combineReducers } from "@reduxjs/toolkit";
import app from "./reducer";
import routing from "./components/Routing/reducer";

const reducer = combineReducers({
    app,
    routing,
});

const root_reducer = (state: any, action: any) => {

    return reducer(state, action);
}

export default root_reducer;