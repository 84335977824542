import { Grid } from "@mui/material";
import { ArticleProps } from "./types";
import { Link } from "react-router-dom";


const OneBigArticle = (props: ArticleProps) => {
    const { article } = props;

    return (
        <Grid xs={12} container className="one-big-article">
                <Grid xs={8} className="oba-container" container spacing={2}>
                <Link to={article.navlink} className="oba-link">
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <img src={article.articleImage} className="oba-image"/>
                        </Grid>
                        <Grid item xs={12} md={4} className="oba-text">
                                <div className="district">{article.location}</div>
                                <div className="main-title">{article.title}</div>
                                <div className="short-desc">{article.shortDesc}</div>
                            <Grid xs={12} container spacing={4} className="bottom-container">
                                <Grid xs={2} className="author-avatar">
                                    {/* <img src={article.authorImage} className="avatar-photo" /> */}
                                </Grid>
                                <Grid xs={5} className="article-date" sx={{display: { xs: 'none', sm: 'block' }}}>
                                    {article.date}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Link>
                </Grid>
            
        </Grid>
    );
};

export default OneBigArticle;