import { mainKazimierzArticle } from "../../data/kazimierz-articles";
import OneBigArticle from "../../modules/OneBigArticle/OneBigArticle";

const Kazimierz = () => {
  
    const article = mainKazimierzArticle;
    
    return (
        <div className="sub-page-container">
            <OneBigArticle article={article} />
        </div>
    );
  }
  
  export default Kazimierz;