import { Article } from "../../models/article";

export const sladyPrzedlokacyjnegoKrakowa: Article = {
    navlink: '/slady-przedlokacyjnego-krakowa',
    articleImage: 'images/KosciolSwWojciecha.jpg',
    location: 'Stare Miasto',
    title: 'Ślady przedlokacyjnego Krakowa',
    shortDesc: 'Mimo, iż lokacja Krakowa miała miejsce 5 czerwca 1257, do dziś możemy spotkać ślady przedlokacyjnego miasta.',
    date: '1 października 2023',
    authorImage: 'images/KamilMarkiewicz.jpg',
    authorName: 'Kamil Markiewicz'
}