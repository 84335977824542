import { Article } from "../../models/article";

export const kamiennyKrag : Article = {
    navlink: '/kamienny-krag',
    articleImage: 'images/KamiennyKrag.jpg',
    location: 'Podgórze',
    title: 'Kraków za czasów słowiańskich - co o nim wiemy?',
    shortDesc: 'Kamienny krąg, kopiec Kraka oraz rzeźba Światowida to symbole, które przypominają nam o słowiańskiej i pogańskiej przeszłości. Czas przyjrzeć się tym miejscom bliżej.',
    date: '15 października 2023',
    authorImage: 'images/KamilMarkiewicz.jpg',
    authorName: 'Kamil Markiewicz'
};