import { Grid } from "@mui/material";

const KamiennyKrag = () => {
    return (
        <div className="sub-page-container">
            <Grid container md={8} className="article-content-container">
                <div className="article-date">
                    15 października 2023
                </div>
                <div className="article-title">
                    Kraków za czasów słowiańskich - co o nim wiemy?
                </div>
                <div className="article-content">
                    Niewiele zostało do dnia dzisiejszego śladów Polski przedchrześcijańskiej. Nawet w szkołach najczęściej „poważna” nauka historii rozpoczyna się od Mieszka I. Czasem się wspomni, że kiedyś „też coś było”, jednak nie poświęcamy temu zagadnieniu zbyt wiele uwagi. A jednak Kraków może pochwalić się miejscami związanymi z kulturą pogańską, zachowaną do dnia dzisiejszego. Jedno z tych miejsc być może jest Wam dobrze znane. Zapraszamy do poznania historii krakowskich pogan.
                </div>
                <img src="images/KamiennyKrag.jpg" className="article-image" />
                <div className="article-content">
                    W rejonie Kamieniołomu Libana, znajduje się Wzgórze, zwane Wzgórzem Swaroga. Swaróg był słowiańskim bogiem ognia i kowalstwa. Był również ojcem Daźboga i Swarzożyca, innych postaci z mitologii słowiańskiej. Gdybyśmy mieli znaleźć odniesienie w najlepiej pewnie nam znanej mitologii, czyli tej greckiej, to Swaróg byłby takim słowiańskim Hefajstosem. W województwie pomorskim znajdziemy nawet miejscowość, której nazwa pochodzi od boskiego imienia – Swarożyn. Nieopodal kręgu możemy znaleźć również święte drzewo o wielu pniach.
                </div>
                <div className="article-content">
                    Wróćmy jednak na wzgórze. Pierwsze, co ukazuje się naszym oczom, po wejściu na wzniesienie, to ruiny budynków. Są to pozostałości po dawnej austriackiej prochowni z czasów Twierdzy Kraków. Gdy jednak obejdziemy ruiny, naszym oczom ukaże się kamienny krąg, złożony z 8 menhirów, które są symbolami bogów żywiołów. W książce pt. „Słowiańscy bogowie” autorstwa Joachima Antoniego Szyca (1824-1896), wydanej w 1865 roku, wśród Bogów Żywiołów możemy znaleźć następujące mitologiczne postaci:
                </div>
                <div className="article-content">
                    Wodan – bóg wód, pan morza oraz Wodana – żona Wodana, bogini wód, która panowała również nad upałem i mrozem,
                </div>
                <div className="article-content">
                    Pogwizd – bóg wiatrów w służbie Wodana, Marna – żeński odpowiednik Pogwizda, Pochwist – oraz Fertuna – bogini wiatrów,
                </div>
                <div className="article-content">
                    Pochwist – bóg wichrów,
                </div>
                <div className="article-content">
                    Wajan – bóg ognia,
                </div>
                <div className="article-content">
                    Pogoda – bogini pięknego i złotego dnia,
                </div>
                <div className="article-content">
                    Pliwnik – bóg bogactw podziemnych,
                </div>
                <div className="article-content">
                    Świątybór – bóg borów, lasów i gajów, Dziewonja – pani lasów,
                </div>
                <div className="article-content">
                    Wołos – bóg pasterzy.
                </div>
                <div className="article-content">
                    Dzieląc te postaci na powyższe grupy rzeczywiście uzyskujemy osiem grup bóstw, czemu może odpowiadać znajdujące się w kręgu 8 menhirów – niestety w żadnych źródłach, do których dotarłem nie udało mi się znaleźć potwierdzenia tej tezy, tak więc proszę powyższą listę rozpatrywać bardziej w kategorii tezy i ciekawostki, aniżeli faktu. Gdyby kogoś zainteresowała bardziej tematyka słowiańskich bogów, wspomniana książka na dzień pisania artykułu dostępna jest w lublińskiej Bibliotece Cyfrowej - <a href="http://bc.wbp.lublin.pl/dlibra/doccontent?id=3663">kliknij, aby przejść do biblioteki</a>
                </div>
                <div className="article-content">
                    Święty Krąg na Libanie to nowy układ, gdyż został ułożone w tym miejscu na początku XXI wieku. W miejscu tym rozpoczęto także prace nad wykonaniem kręgu 12 mocy z otoczaków, jednak prace nie zostały dokończone. Krąg wykorzystywany jest do współczesnych obrzędów, organizowanych między innymi przez Rodzimy Kościół Polski i Rodzimą Wiarę, które od 90 lat XX wieku są zarejestrowane w Polsce jako legalne związki wyznaniowe.
                </div>
                <div className="article-content">
                    Ze wzgórza widać inny obiekt, zbudowany w czasach pogańskich, jednocześnie będący najstarszym krakowskim zabytkiem (pod warunkiem, że uznamy kopiec za budowlę) – Kopiec Kraka. Budowla ta powstała na początku średniowiecza, najpewniej między VII a X wiekiem i została wzniesiona przez Wiślan jako miejsce pochówku pierwszego krakowskiego władcy, choć do dziś archeologicznie nie udało się potwierdzić, że w tym miejscu rzeczywiście spoczął legendarny władca.
                </div>
                <img src="images/KopiecKrakusa.jpg" className="article-image" />
                <div className="article-content">
                    Podobnie jak przy pierwszym z miejsc opisywanych w niniejszym artykule, i tutaj wokół obiektu napotkamy „ruiny”. I również podobnie jak w pierwszym przypadku są to pozostałości Twierdzy Kraków – w tym miejscu warto wspomnieć, że dawno temu Kopiec Kraka był otoczony podobnym Fortem, jak obecnie Kopiec Kościuszki. Był to Fort Cytadelowy 33 „Krakus”, powstały w latach 1854-1857. W roku 1933 przystąpiono do prac archeologicznych, podczas których rozebrano część okalającą kopiec, natomiast wciąż znajdował się tam budynek koszar, który rozebrano nieco później, bo w 1954 roku. Podczas remontu w 2014 roku przy pomocy wapiennych kamieni postanowiono zrekonstruować zarys muru, które są właśnie wspomnianymi na początku „ruinami”. Natomiast wpatrując się w otoczenie kopca możemy dostrzec pozostałości suchej fosy, które są faktyczną pozostałością po forcie.
                </div>
                <div className="article-content">
                    Warto na moment jeszcze wrócić do tematu prac archeologicznych. Zlecone i sfinansowane przez Mariana Dąbrowskiego, a prowadzone pod nadzorem naukowców z Polskiej Akademii Umiejętności badania nie pozwoliły odnaleźć śladów pochówku władcy, ani nie udało się dokładnie oszacować daty i celu powstania obiektu, znaleziono jedynie pozostałości po około 300-letnim dębie, metalową skuwkę typu awarskiego, datowaną na VIII wiek, a także krzemienne narzędzia. Udowodniono również, że pierwotny kopiec był mniejszych rozmiarów, niż obecnie (był kilkukrotnie nadbudowywany). Ale warto w tym miejscu pokazać fotografię, która przedstawia proces badań archeologicznych. Fotografia pochodzi ze zbiorów NAC, Koncern Ilustrowany Kurier Codzienny - Archiwum Ilustracji (sygn. 1-N-1027a-2).
                </div>
                <img src="images/KopiecKrakusaPodczasWykopalisk.jpg" className="article-image" />
                <div className="article-content">
                    W czasach pogańskich na kopcu odbywały się uroczystości poświęcone zmarłym. Miały one miejsce w dniu równonocy wiosennej. W tym czasie również miały miejsce obrzędy związane z oczyszczeniem. Z tego okresu wywodzi się również kultywowane do dziś Święto Rękawki. Obecnie podczas takiej imprezy odbywają się rekonstrukcje walk, można przejść się słowiańską wioską, kupić jedną ze słowiańskich pamiątek, a także coś zjeść, choć w tym wypadku akurat ciężko liczyć na słowiańskie średniowieczne przysmaki. Jest to też miejsce uwielbiane przez turystów, gdyż roztacza się stąd jeden z najpiękniejszych widoków na Kraków.
                </div>
                <div className="article-content">
                    Po wprowadzeniu chrześcijaństwa, chcąc ukrócić obrzędy pogańskie, nieopodal Kopca Kraka wybudowano mały kościółek (datowany na początek XI wieku). Wcześniej w jego miejscu znajdowała się rotunda pochodząca z około 1000 roku. Postanowiono świątyni nadać wezwanie św. Benedykta, które jest dosyć symboliczne – z jednej strony święto św. Benedykta przypada na 21 marca, który był dla pogan ważnym dniem (równonoc wiosenna), z drugiej imię św. Benedykta ma charakter egzorcyzmujący, więc prawdopodobnie nadano je, by walczyć z pogańskimi obrządami mającymi miejsce nieopodal.
                </div>
                <div className="article-content">
                    U stóp Wawelu, idąc od strony ulicy Grodzkiej natrafiamy na posąg w kształcie słupa, na który składają się cztery postaci: Perun, Daźbóg, Mokosz oraz Łada. Jest to kopia najsłynniejszej zachowanej rzeźby pogańskiej, zwanej Idolem bądź Światowidem ze Zbrucza. Rzeźba została wyłowiona w 1848 roku z rzeki Zbrucz w pobliżu wsi Liczkowce na Podolu. Trzy lata później trafiła do Muzeum Archeologicznego w Krakowie, gdzie znajduje się do dziś. Bogowie patrzą w cztery strony świata, natomiast pion rzeźby symbolizuje kolejno: Niebiosa (Prawia), Ziemię (Jawia) oraz Podziemia (Nawia). Obiekt datowany jest na XI wiek, mógł już zatem powstać w czasach po chrystianizacji Polski.
                </div>
                <img src="images/Swiatowid.jpg" className="article-image" />
                <div className="article-content">
                    Natomiast Światowid, którego możemy obserwować pod Wawelem powstawał w latach 1963-1968, a twórcami kopii byli Józef i Waldemar Wesołowscy. Pomnik został odsłonięty w 1968, z okazji obchodów 1000-lecia państwa polskiego. W 2008 roku doczekał się renowacji. Niekiedy pod pomnikiem można znaleźć zapalone znicze, przyniesione kwiaty, bądź ofiary.
                </div>
                <div className="article-content">
                    Do dziś w Krakowie istnieją grupy, które zafascynowane dawną tradycją naszych przodków odprawiają różnego rodzaju dawne obrzędy. I tak możemy spotkać m.in. celtyckie święto ognia, zwane Beltaine, Święto Rusalne organizowane przez Wolnych Rodzimowierców Krakowa, Jesienne Dziady (Gromada MIR) i chyba najbardziej popularne wydarzenie, czyli Rękawka, mające miejsce obecnie w pierwszy wtorek po Wielkanocy, a historycznie obchodzone po pierwszej wiosennej pełni. 
                </div>
            </Grid>
        </div>
    );
};

export default KamiennyKrag;