import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { ArticleCardProps } from "./types";

const ThreeColumnsArticleCard = (props: ArticleCardProps) => {
    const {article } = props;

    return (
        <Grid xs={12} className="three-columns-article-card">
            <Link to={article.navlink} className="article-link">
                <Grid xs={12} className="article-container">
                    <Grid xs={12}>
                        <img src={article.articleImage} className="article-image"/>
                    </Grid>
                    <Grid xs={12} className="article-text">
                            <div className="district">{article.location}</div>
                            <div className="main-title">{article.title}</div>
                            <div className="short-desc">{article.shortDesc}</div>
                    </Grid>
                    <Grid xs={12} container className="bottom-container">
                    <Grid xs={2} className="author-avatar">
                        {/* <img src={article.authorImage} className="avatar-photo" /> */}
                    </Grid>
                    <Grid xs={5} className="article-date" sx={{display: { xs: 'none', sm: 'block' }}}>
                        {article.date}
                    </Grid>
                    </Grid>
                    
                </Grid>
            </Link>
        </Grid>
    );
};

export default ThreeColumnsArticleCard;