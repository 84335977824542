import { Grid } from "@mui/material";
import { ThreeColumnsArticlesProps } from "./types";
import ArticleCard from "./ThreeColumnsArticleCard";


const ThreeColumnsArticles = (props: ThreeColumnsArticlesProps) => {
    const { articles } = props;

    return (
        <Grid xs={12} container className="three-columns-container">
            <Grid xs={8} container columnSpacing={2}>
                <Grid item xs={12} md={4}>
                    <ArticleCard article={articles[0]} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ArticleCard article={articles[1]} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ArticleCard article={articles[2]} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ThreeColumnsArticles;