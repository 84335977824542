import { Grid } from "@mui/material";

const SpacerPoNajpopularniejszychKrakowskichTeatrach = () => {

    return (
<div className="sub-page-container">
            <Grid container md={8} className="article-content-container">
                <div className="article-date">
                    8 października 2023
                </div>
                <div className="article-title">
                    Spacer po najpopularniejszych krakowskich teatrach
                </div>
                <div className="article-content">
                    Wśród wielu atrakcji turystycznych w Krakowie, sporo dla siebie znajdą również wielbiciele teatru. Piękne budynki, wspaniała historia, wybitni artyści - każdy z odwiedzonych w dzisiejszym artykule teatrów jest miejscem wyjątkowym i niezwykłym. Zapraszamy serdecznie na tą podróż śladami krakowskiego aktorstwa, którą rozpoczniemy od Teatru im. Słowackiego.
                    </div>
                <img src="images/TeatrSlowackiego.jpg" className="article-image" />
                <div className="article-content">
                    Zaczynamy nie od najstarszego, ale z pewnością od najpiękniejszego teatru, jakim jest Teatr im. Juliusza Słowackiego. Budowę tego pięknego gmachu zlokalizowanego na Placu Św. Ducha rozpoczęto w roku 1891. Był to wówczas pierwszy budynek, który posiadał oświetlenie elektryczne, za nim zresztą znajdowała się specjalnie przeznaczona do tego celu elektrownia - dzisiejsza Scena Miniatura, czyli tzw. "mała scena". Obydwie budowle zostały zaprojektowane przez Jana Zawiejskiego. Uroczyste otwarcie teatru nastąpiło 21 października 1893 roku, gdzie zaprezentowano fragmenty utworów Adama Mickiewicza, Juliusza Słowackiego oraz Aleksandra Fredry. Warto w tym miejscu dodać, że wówczas teatr nazywany był Teatrem Miejskim. Imię Juliusza Słowackiego w nazwie instytucji pojawiło się dopiero w 1909 roku. 
                    </div>
                <div className="article-content">
                    To właśnie tutaj prapremiery miały najwybitniejsze dzieła Stanisława Wyspiańskiego, "Wesele" (1901) oraz "Wyzwolenie" (1903). Tutaj też pojawiły się pierwsze realizacje "Kordiana" Juliusza Słowackiego, "Dziadów" Adama Mickiewicza czy "Nie-boskiej Komedii" Zygmunta Krasińskiego, a także zadebiutował Stanisław Ignacy Witkiewicz, szerzej znany pod pseudonimem Witkacy.
                    </div>
                <div className="article-content">
                    Przez lata działania Teatr prowadziło wielu wybitnych dyrektorów, dość wspomnieć chociażby Juliusza Osterwę czy Karola Frycza. A wśród artystów przewijały się takie osobistości jak Tadeusz Kantor, Helena Modrzejewska czy Gustaw Holoubek. Obecnie na deskach Teatru imienia Juliusza Słowackiego możemy spotkać m.in. takich aktorów jak Andrzej Grabowski, Jan Peszek, Dominika Bednarczyk, Mateusz Janicki, Bożena Adamek, Hanna Bieluszko czy Mateusz Bieryt. Dyrektorem artystycznym teatru jest obecnie Krzysztof Głuchowski.
                    </div>
                <img src="images/TeatrStary.jpg" className="article-image" />
                <div className="article-content">
                    Z Placu Świętego Ducha przenosimy się na Plac Szczepański, gdzie swoją siedzibę ma Narodowy Stary Teatr im. Heleny Modrzejewskiej. Nazwa nie jest przypadkowa, bowiem mamy do czynienia z najstarszym krakowskim, a jednocześnie drugim najstarszym teatrem w kraju (najstarszy jest warszawski Teatr Narodowy). Przy współpracy posła Feliksa Oraczewskiego oraz aktora Mateusza Witkowskiego udało się dnia 20 października 1781 roku otworzyć teatr, wówczas mieszczący się w Pałacu Spiskim (Rynek Główny 34). Tam prezentowano sztuki do 1799 roku, by później przenieść się do obecnego budynku. W XIX wieku w Teatrze grano najwybitniejsze dzieła m.in. Szekspira, Moliera czy Felińskiego, a także opery (Kurpiński, Cherubini).
                    </div>
                <div className="article-content">
                    W 1865 roku na scenie teatru zadebiutowała Helena Modrzejewska, późniejsza patronka teatru. Po wybudowaniu gmachu Teatru Miejskiego (przypomnienie: dzisiejszego Teatru im.Słowackiego, o którym pisaliśmy wyżej), Stary Teatr praktycznie przestał istnieć. Jako samodzielny byt artystyczny powrócił w roku 1954, dwa lata później pojawiła się nazwa Państwowy Stary Teatr im. Heleny Modrzejewskiej. Z czasem teatr odzyskał swój dawny blask, a tworzył w nim między innymi reżyser Andrzej Wajda czy Jerzy Jarocki.
                    </div>
                <div className="article-content">
                    Dziś na deskach Narodowego Teatru Starego im. Heleny Modrzejewskiej, możemy spotkać m.in. takich aktorów jak Anna Dymna, Jan Peszek, Krzysztof Globisz, Ewa Kolasińska, Magda Grąziowska czy Krzysztof Zawadzki. Dyrektorem artystycznym teatru jest obecnie Waldemar Raźniak.
                    </div>
                <img src="images/TeatrBagatela.jpg" className="article-image" />
                <div className="article-content">
                    Przenosimy się na ulicę Karmelicką 6, gdzie swoją siedzibę ma Teatr Bagatela im. Tadeusza Boya-Żeleńskiego. Ten powstały 25 października 1919 roku teatr został zaprojektowany przez Janusza Zarzeckiego jeżeli chodzi o bryłę budynku, natomiast za wnętrza odpowiadał malarz Henryk Uziembło. Po zaledwie 7 latach istnienia, z powodu problemów finansowych w miejsce teatru powstało tutaj kino. Dwa lata później budynek został zniszczony na skutek pożaru. Dopiero w 1938 poddano go modernizacji i otworzono kino Scala.
                </div>
                <div className="article-content">
                    Kolejne lata to już powrót do korzeni, czyli teatru. W latach 1946-1948 siedzibę swoją miał tutaj Teatr Kameralny, następnie Teatr Młodego Widza (od 1949), a także Teatr Rozmaitości (od 1957). Do pierwotnej nazwy wrócono w 1970 ("Bagatela"), a dwa lata później pojawił się patron - Tadeusz Boy-Żeleński.
                </div>
                <div className="article-content">
                    Dziś na deskach Teatru "Bagatela" im. Tadeusza Boya-Żeleńskiego, możemy spotkać m.in. takich aktorów jak Krzysztof Bochenek, Przemysław Branny, Karolina Chapko, Urszula Grabowska, Magdalena Walach. Dyrekotrem artystycznym teatru jest obecnie Krzysztof Materna.
                </div>
                <img src="images/TeatrGroteska.jpg" className="article-image" />
                <div className="article-content">
                    Tym razem oprócz lokalizacji zmienimy również specjalizację, gdyż znajdujący się przy ulicy Skarbowej 2 Teatr "Groteska" jest przede wszystkim teatrem formy - głównie znany z przedstawień lalkowych, ale także teatra tańca, choć nie brakuje tu również tradycyjnych form. Do 2023 roku Groteska była również organizatorem znanego w krakowie wydarzenia, jakim jest coroczna Wielka Parada Smoków, która odbywa się w czerwcu pod Wawelem.
                </div>
                <div className="article-content">
                    Otwarcie teatru miało miejsce 9 czerwca 1945, które uświetniła premiera "Cyrku Tarabumba" w reżyserii Władysława Jaremy. To właśnie on, wraz z żoną Zofią, która z kolei została pierwszą dyrektorką teatru byli twórcami tego miejsca. Początkowo działali oni pod nazwą Teatr Lalki, Maski i Aktora Groteska. 
                </div>
                <div className="article-content">
                    Dziś na deskach Teatru Groteska, możemy spotkać m.in. takich aktorów jak Małgorzata Brożonowicz-Sienkiewicz, Ilona Buchner czy Dominika Guzek. Dyrekotrem artystycznym teatru jest obecnie Adolf Weltschek.
                </div>
                <img src="images/TeatrStu.jpg" className="article-image" />
                <div className="article-content">
                    Kolejnym punktem naszej trasy jest aleja Krasińskiego 16, czyli Teatr Stu. Obecny teatr działa od lipca 1997 roku, ale kontynuuje tradycję Teatru STU, powstałego 20 lutego 1966 roku dzięki staraniom Krzysztofa Jasińskiego.
                </div>
                <div className="article-content">
                    Oprócz wartości artystycznych, z miejscem tym wiąże się też tragiczna historia, bowiem to właśnie na parkingu Teatru STU, 10 października 1991 po spektaklu Pan Twardowski, zamordowani zostali Andrzej Zaucha oraz Zuzanna Leśniak. Zabójcą okazał się mąż aktorki, francuski reżyser Yes Goulais, a motywem zazdrość. Z kolei w 2021 roku przed budynkiem teatru posadzono grab "Gnat", na pamiątkę zmarłego aktora tego teatru, Dariusza Gnatowskiego.
                </div>
                <div className="article-content">
                    Dziś na deskach Teatru Stu, możemy spotkać m.in. takich aktorów jak Piotr Cyrwus, Magdalena Czerwińska, Andrzej Deskur, Karolina Gorczyca, Andrzej Grabowski, Edward i Olaf Lubaszenko czy Piotr Polk. Dyrekotrem artystycznym teatru jest obecnie Krzysztof Jasiński.
                </div>
                <img src="images/Cricoteka.jpg" className="article-image" />
                <div className="article-content">
                    Udajemy się na ulicę Nadwiślańską 2, gdzie mieści się siedziba muzeum Cricoteka. Tutaj możemy poznać historię teatrów Cricot i Cricot 2, które niegdyś były ważnymi punktami na teatralnej mapie Krakowa. 
                </div>
                <div className="article-content">
                    Pierwszy z nich, Cricot powstł w 1933 roku, za sprawą lokalnych plastyków i awangardowych pisarzy, a głównym założycielem był Józef Jarema. Pierwsze występy odbywały się w Domu pod Krzyżem, znajdującym się na rogu Placu Św. Ducha i ulicy Szpitalnej. Dwa lata później teatr przeniósł się do Domu Plastyków przy Łobzowskiej 3. Współtwórcami spektakli obok założyciela Józefa Jaremy byli m.in. Maria Jarema, Henryk Gotlib czy Zbigniew Pronaszko. Teatr działał do 1939 roku.
                </div>
                <div className="article-content">
                    W 1955 roku, za sprawą Marii Jaremy i Tadeusza Kantora utworzony został Cricot 2. Był jednym z najważniejszych teatrów eksperymentalnych na świecie. Pierwszym miejscem działania teatru był, podobnie jak w przypadku poprzednika, Dom Plastyków. Od 1961 roku mieścił się on w piwnicach Galerii Krzysztofory. Dziś Muzeum Krakowa w tym miejscu posiada wystawę dotyczącą Tadeusza Kantora, gdzie można znaleźć m.in. orygianlne ławki, które brały udział w spektaklu "Umarła klasa". Przesłaniem teatru było zlikwidowanie dystansu pomiędzy sceną a widownią. Zamiast oddzielania artystów od widowni, stawiano na przenikanie się ich podczas spektakli.
                </div>
                <div className="article-content">
                    Oprócz założycieli w teatrze udzielali się m.in. Maria Stangret-Kantor, Jan Książek, Zbigniew Gostomski czy Jerzy Nowak. Teatr zakończył swoją działalność w 1991 roku, po śmierci Tadeusza Kantora. Dziś historię obu tych niezwykłych miejsc możemy zbliżyć w powstałym w tym celu muzeum, zwanym Cricoteką.
                </div>
                <img src="images/TeatrVariete.jpg" className="article-image" />
                <div className="article-content">
                    Czas na najmłodszy na całej naszej trasie teatr, który powstał 30 kwietnia 2014 roku. Jest to Teatr muzyczny Variété, mieszczący się przy ulicy Grzegórzeckiej 71. Dawniej, w obecnej siedzibie teatru mieściło się kinoteatr Związkowiec. W teatrze prezentowane są musicale, komedie muzyczne, wodewile, burleska, a także inne formy sztuki.
                </div>
                <div className="article-content">
                    Dziś na deskach Teatru Variété, możemy spotkać m.in. takich aktorów jak Paweł Tucholski, Barbara Kurdej-Szatan, Tadeusz Huk, Iwona Bielska czy Katarzyna Galica. Dyrekotrem artystycznym teatru jest obecnie Janusz Szydłowski.
                </div>
                <img src="images/TeatrLudowy.jpg" className="article-image" />
                <div className="article-content">
                    Na koniec wyprawy przenosimy się na Nową Hutę, gdzie na Osiedlu Teatralnym 34 swoją siedzibę ma założony 3 grudnia 1955 Teatr Ludowy. Budynek został zaprojektowany przez Janusza Ingardena, głównego projektanta miasta Nowa Huta, a także przez Martę Ingarden oraz Jana Dąbrowskiego. Pierwszym spektaklem, który mogli zobaczyć mieszkańcy Nowej Huty była sztuka "Krakowiacy i Górale" Wojciecha Bogusławskiego. Co ciekawe, Teatr Ludowy wystawia sztuki również na Rynku Głównym, gdzie w budynku Wieży Ratuszowej znajduje się Scena Pod Ratuszem.
                </div>
                <div className="article-content">
                    Dziś na deskach Teatru Ludowego, możemy spotkać m.in. takich aktorów jak Piotr Pilitowski, Marta Bizoń, Andrzej Franczyk czy Jagoda Pietruszkówna. Dyrekotrem artystycznym teatru jest obecnie Małgorzata Bogajewska.
                </div>
                <div className="article-content">
                    Na koniec warto wspomnieć, że mimo, iż odwiedziliśmy dzisiaj sporo miejsc, nie są to wszystkie miejsca na krakowskiej mapie teatrów - jest ich zdecydowanie zbyt wiele, by zmieścić wszystkie w jednym artykule. Na poniższej mapie zostały oznaczone wszystkie wymienione w artykule teatry, a na spacer śladami krakowskich teatrów można udać się według kolejności z artykułu - zostały ułożone w sposób uwzględniający najmniejsze odległości pomiędzy nimi.
                </div>
                <div className="article-map">
                <iframe src="https://www.google.com/maps/d/embed?mid=1LLQUzO7FAwUtyzAhnkhqqb1NfTBYWtKG&hl=pl&ehbc=2E312F"></iframe>
                </div>
            </Grid>
        </div>
    );
}

export default SpacerPoNajpopularniejszychKrakowskichTeatrach;