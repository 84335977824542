import { Article } from "../models/article";
import { kazimierskiRatusz } from "./kazimierz/kazimierski-ratusz";
import { kamiennyKrag } from "./podgorze/kamienny-krag";
import { spacerPoNajpopularniejszychKrakowskichTeatrach } from "./pozostale/sladami-krakowskich-teatrow";
import { sladyPrzedlokacyjnegoKrakowa } from "./stareMiasto/slady-przedlokacyjnego-krakowa";

export const mainPageMainArticle: Article = sladyPrzedlokacyjnegoKrakowa;

export const threeMainPageArticles : Article[] = [
    spacerPoNajpopularniejszychKrakowskichTeatrach,
    kazimierskiRatusz,
    kamiennyKrag
];